<template>
  <h1 class="text-center">404 Pages</h1>
</template>

<script>
export default {
  name: "404Pages"
}
</script>

<style scoped>

</style>